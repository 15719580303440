import { FC } from "react";

export interface InfoCardProps {
  title: string;
  description: string;
  Icon: JSX.Element;
}

export const InfoCard: FC<InfoCardProps> = ({ title, description, Icon }) => {
  return (
    <div className="border border-grey-300 rounded p-[24px] space-y-[16px] shadow-md">
      <div className="w-[46px] h-[46px] bg-primary-100 flex items-center justify-center">
        {Icon}
      </div>
      <p className="font-semibold text-18">{title}</p>
      <p
        className="text-16"
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
    </div>
  );
};
