import { SVGProps } from "react";

export const Directions = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1_14244)"
      >
        <path d="M12 21v-4M12 13V9M12 5V3M10 21h4M8 5v4h11l2-2-2-2H8zM14 13v4H6l-2-2 2-2h8z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_14244">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
