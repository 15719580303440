import { SVGProps } from "react";

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1_9703)"
      >
        <path d="M5 12h14M13 18l6-6M13 6l6 6"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_9703">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
