export const Trophy = () => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1_20688)"
      >
        <path d="M8 21h8M12 17v4M7 4h10M17 4v8a5 5 0 11-10 0V4"></path>
        <path d="M5 11a2 2 0 100-4 2 2 0 000 4zM19 11a2 2 0 100-4 2 2 0 000 4z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_20688">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
