import { useMemo, useRef, useState } from "react";
import { Close, Hamburger } from "./menu/menu-icons";
import { Menu } from "./menu/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "../../RoutePath";
import { useScrollPosition } from "../../hooks/useScrollPosition";

export const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const NavbarIcon = useMemo(() => {
    return isMenuOpen ? Close : Hamburger;
  }, [isMenuOpen]);
  const menuRef = useRef<HTMLDivElement>(null);
  const scrollPosition = useScrollPosition();

  const navClass = useMemo(() => {
    if (
      scrollPosition > 0 ||
      location.pathname !== RoutePath.ABOUT ||
      isMenuOpen
    ) {
      return `fixed opacity-100`;
    } else {
      return "fixed opacity-[.65]";
    }
  }, [scrollPosition, isMenuOpen, location.pathname]);

  return (
    <div
      ref={menuRef}
      className={`w-full bg-black z-[10000]  ${navClass} ${
        isMenuOpen ? "h-screen w-full fixed left-0 top-0 overflow-hidden" : ""
      }`}
    >
      <div className="h-[56px] lg:h-[80px] max-w-[1440px] my-0 mx-auto w-full flex items-center justify-between text-white px-[16px]">
        <p
          className="text-white text-32 font-bold cursor-pointer"
          onClick={() => navigate(RoutePath.HOME)}
        >
          <img
            src="/images/logo.png"
            alt="logo"
            className="h-[36px] lg:h-[56px]"
          />
        </p>
        <div className="hidden lg:flex">
          <Menu />
        </div>
        <div className="lg:hidden">
          <NavbarIcon onClick={() => setIsMenuOpen(!isMenuOpen)} />
        </div>
      </div>
      {isMenuOpen && <Menu onMenuItemClick={() => setIsMenuOpen(false)} />}
    </div>
  );
};
