import { FC } from "react";

interface StatisticCardProps {
  count: number;
  title: string;
}

export const StatisticCard: FC<StatisticCardProps> = ({ count, title }) => {
  return (
    <div className="w-[280px] h-[160px] shadow-md px-[16px] py-[20px] rounded">
      <p className="text-56 font-bold">{count}</p>
      <p className="text-grey-500 text-26">{title}</p>
    </div>
  );
};
