import { FC } from "react";

interface TitleProps {
  content: string;
  className?: string;
  as?: "h1" | "h2";
  bolded?: boolean;
}

export const Title: FC<TitleProps> = ({
  content,
  className = "",
  as = "h1",
  bolded = false,
}) => {
  const CustomHTag = as;

  return (
    <CustomHTag
      className={`font-semibold text-32 text-grey-800 ${className} ${
        bolded ? "font-bold" : ""
      }`}
    >
      {content}
    </CustomHTag>
  );
};
