import { SVGProps } from "react";

export const Instagram = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1_11452)"
      >
        <path d="M16 4H8a4 4 0 00-4 4v8a4 4 0 004 4h8a4 4 0 004-4V8a4 4 0 00-4-4z"></path>
        <path d="M12 15a3 3 0 100-6 3 3 0 000 6zM16.5 7.5v.001"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_11452">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
