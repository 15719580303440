import { useParams } from "react-router-dom";
import { Title } from "../../components/typography/Title";
import { trailList } from "../teams/teamData";
import { Download } from "../../components/icons/Download";
//@ts-ignore
import toGeoJson from "@mapbox/togeojson";
import length from "@turf/length";
import bbox from "@turf/bbox";
import { StatisticCard } from "../../components/elements/StatisticCard";
import { useEffect, useMemo, useRef, useState } from "react";
// import * as mapboxgl from "mapbox-gl";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Decimation,
  DecimationAlgorithm,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
  TooltipItem,
  TooltipModel,
} from "chart.js";
import { MapPin } from "../../components/icons/MapPin";
import { Position, lineString } from "@turf/helpers";
import Map, { Layer, LayerProps, MapRef, Source } from "react-map-gl";
import { useWindowResize } from "../../hooks/useWindowResize";
import mapboxgl from "mapbox-gl";
import { NotFound } from "../NotFound";
import { Loader } from "../../components/layout/Loader";
import { Info } from "../../components/icons/Info";

// @ts-ignore
// prettier-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Decimation,
);

interface GraphData {
  distances: number[];
  elevations: number[];
}

export const TrailDetails = () => {
  const { isMobile } = useWindowResize();
  const { id } = useParams<{ id: string }>();
  const trail = trailList.find((x) => x.id === Number(id))!;
  const [geoJson, setGeoJson] = useState();
  const [graphData, setGraphData] = useState<GraphData>();
  const [isLoading, setIsLoading] = useState(true);
  const [pointData, setPointData] = useState<Array<number>>();
  const [rangeValue, setRangeValue] = useState(0);
  const chartRef = useRef<ChartJS>(null);

  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    if (trail)
      fetch(trail.gpx!).then((res) => {
        res.text().then((x) => {
          var gpx = new DOMParser().parseFromString(x, "text/xml");
          const rawGeoJSON = toGeoJson.gpx(gpx);
          setGeoJson(rawGeoJSON);
          const p = rawGeoJSON.features[0].geometry.coordinates[0];
          setPointData([p[0], p[1]]);
          setIsLoading(false);
        });
      });
  }, [trail, trail?.gpx]);

  useEffect(() => {
    if (geoJson) {
      // @ts-ignore
      const { coordinates } = geoJson.features[0].geometry;
      let points: Position[] = [];
      let distances: number[] = [];
      let elevations: number[] = [];
      coordinates.forEach((coord: number[], index: number) => {
        points.push([coord[0], coord[1]]);
        distances.push(points.length > 1 ? length(lineString(points)) : 0);
        elevations.push(coord[2]);
      });
      setGraphData({
        distances,
        elevations,
      });
    }
  }, [geoJson]);

  const distance = useMemo(() => {
    if (geoJson) return Number(length(geoJson).toFixed(1));
  }, [geoJson]);

  const elevation = useMemo(() => {
    if (geoJson) {
      // @ts-ignore
      const { coordinates } = geoJson.features[0].geometry;
      let elevation = 0;
      coordinates.forEach((coord: any, index: any) => {
        if (index === coordinates.length - 1) return; // stop 1 point early since comparison requires 2 points
        const elevationDifference =
          coordinates[index + 1][2] - coordinates[index][2];
        if (elevationDifference > 0) elevation += elevationDifference;
      });
      return Math.round(Number(elevation));
    }
  }, [geoJson]);

  if (!trail) {
    return <NotFound />;
  }

  const onMapLoad = () => {
    const [x1, y1, x2, y2] = bbox(geoJson);
    mapRef.current!.fitBounds([x1, y1, x2, y2], { padding: 38 });
  };

  const decimation = {
    enabled: false,
    algorithm: "lttb" as DecimationAlgorithm,
    samples: 10,
  };

  // const updatePoint = (x: number, y: number) => {
  //   setTimeout(() => {
  //     console.log('====>', x, y)
  //     setPointData([x,y])
  //   }, 1000)
  // }

  const options: ChartOptions<"line"> = {
    responsive: true,
    // onHover: (_, elements) => {
    //   //@ts-ignore
    //   const { coordinates } = geoJson.features[0].geometry;
    //   const i = elements[0].index;
    //   const cor = coordinates[i];
    //   //throttle(() => setPointData([cor[0], cor[1]]), 100)
    //   updatePoint(cor[0], cor[1]);
    //   //setPointData([cor[0], cor[1]]);
    // },
    indexAxis: "x" as const,
    scales: {
      x: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    plugins: {
      decimation: decimation,
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (
            this: TooltipModel<"line">,
            tooltipItems: TooltipItem<"line">[],
          ) {
            return `${Number(tooltipItems[0].label).toFixed(2)} km`;
          },
          label: function (
            this: TooltipModel<"line">,
            tooltipItem: TooltipItem<"line">,
          ) {
            return `${tooltipItem.formattedValue} m`;
          },
        },
      },
    },
  };

  const pointLayer: LayerProps = {
    id: "point",
    type: "circle",
    paint: {
      "circle-radius": 10,
      "circle-color": "#014F86",
    },
  };

  if (isLoading) return <Loader />;

  const findTheClosestIndex = (val: number): number => {
    let index = graphData?.distances.findIndex(
      (x) => Number(x.toFixed(1)) === Number(val.toFixed(1)),
    );
    if (!index || index < 0) {
      return findTheClosestIndex(val + 0.1);
    }
    return index;
  };

  const onStepChange = (e: React.FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    setRangeValue(Number(val));
    let index = findTheClosestIndex(Number(val));
    //@ts-ignore
    const { coordinates } = geoJson.features[0].geometry;
    const p = coordinates[index];
    setPointData([p[0], p[1]]);
    console.log(
      "chart.data.datasets[0].data.length",
      (chartRef.current as ChartJS).data.datasets[0].data.length,
    );
    if (chartRef.current?.tooltip) {
      chartRef.current.tooltip.setActiveElements(
        [
          {
            datasetIndex: 0,
            index: index!,
          },
        ],
        {
          x: graphData!.distances[index!],
          y: graphData!.elevations[index!],
        },
      );
      chartRef.current.update();
    }
  };

  return (
    <div className="max-w-[1440px] my-0 mx-auto px-[16px] pt-[80px] pb-[40px] lg:pb-[80px] lg:pt-[120px]">
      <div className="space-y-[16px]">
        <div className="flex lg:flex-row flex-col lg:justify-between gap-[12px]">
          <div className="flex lg:flex-row flex-col items-center gap-[8px] lg:gap-[32px]">
            <Title content={`${trail.type} ${trail?.name}`} />
            <div className="flex text-grey-500 gap-[8px]">
              <MapPin />{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={`http://www.google.com/maps/place/${trail.x},${trail.y}`}
              >
                {trail.x}, {trail.y}
              </a>
            </div>
          </div>
          <a
            href={trail.gpx}
            download
            className="w-fit self-end me-[38px] flex gap-[4px] text-20 items-center border border-grey-500 rounded px-[10px] py-[4px]"
          >
            <Download /> GPX
          </a>
        </div>
        <div className="flex lg:flex-row flex-col gap-[28px]">
          <div className="space-y-[16px]">
            <div className="flex lg:flex-row flex-col items-center gap-[12px]">
              <StatisticCard title="Dužina" count={distance || 0} />
              <StatisticCard title="Elevacija" count={elevation || 0} />
            </div>
            <div className="w-full">
              {graphData && (
                <>
                  <div className="flex gap-[8px] text-alert-100 items-center mb-[10px]">
                    <Info />
                    <p>Povuci bicikl i poveži graf i mapu</p>
                  </div>
                  <div className="ps-[26px] mb-[10px]">
                    <input
                      type="range"
                      step="0.01"
                      min="0"
                      max={distance}
                      value={rangeValue}
                      className="w-full"
                      onChange={onStepChange}
                    ></input>
                  </div>
                  <Line
                    //@ts-ignore
                    ref={chartRef}
                    options={options}
                    data={{
                      labels: graphData.distances,
                      datasets: [
                        {
                          data: graphData.elevations.map((x, i) => {
                            return {
                              x: graphData.distances[i],
                              y: x,
                            };
                          }),
                          pointRadius: 0,
                          borderColor: "#61A5C2",
                          tension: 0.1,
                          borderWidth: 2,
                        },
                      ],
                    }}
                  />
                </>
              )}
            </div>
          </div>
          {geoJson && (
            <Map
              onLoad={onMapLoad}
              ref={mapRef}
              style={{
                width: "100%",
                height: isMobile ? "300px" : "500px",
              }}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              initialViewState={{
                longitude: trail.y,
                latitude: trail.x,
                zoom: 15,
              }}
              mapStyle="mapbox://styles/mapbox/streets-v12"
            >
              <Source id={trail.name} type="geojson" data={geoJson} />
              <Layer
                id={trail.name}
                type="line"
                source={trail.name}
                layout={{ "line-join": "round", "line-cap": "round" }}
                paint={{
                  "line-color": "red",
                  "line-width": 4,
                }}
              />
              {pointData && (
                <Source
                  type="geojson"
                  data={{
                    type: "Point",
                    coordinates: pointData,
                  }}
                >
                  <Layer {...pointLayer} />
                </Source>
              )}
            </Map>
          )}
        </div>
        {trail.video && (
          <div className="flex items-center justiy-center w-full lg:w-[1000px] h-[230px] lg:h-[600px] pt-[16px] lg:pt-[34px] my-0 mx-auto">
            <iframe
              width="100%"
              height="100%"
              src={trail.video}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
};
