import { SVGProps } from "react";

export const Strava = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_1_11707)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M11 13l4 8 4-8m-4 0L10 3 5 13h10z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_11707">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
