import { FC, SVGProps } from "react";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "light" | "solid" | "outlined";
  Icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

export const Button: FC<ButtonProps> = ({
  type = "button",
  variant = "solid",
  className = "",
  title,
  Icon,
  ...props
}) => {
  let color =
    variant === "solid"
      ? "bg-primary-100 text-grey-0 disabled:bg-grey-10"
      : "text-grey-700";

  if (variant === "outlined") {
    color += " border border-grey-700";
  }

  const iconClass = Icon ? "flex items-center gap-[8px]" : "";

  return (
    <button
      className={`${color} text-16 font-semibold py-[10px] px-[24px] cursor-pointer rounded w-full disabled:text-grey-80 whitespace-nowrap ${iconClass} ${className}`}
      type={type}
      {...props}
    >
      {Icon && <Icon />}
      {title}
    </button>
  );
};
