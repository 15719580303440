import { Route, Routes } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";

export const App = () => {
  return (
    <Routes>
      {AppRoutes.map((route, i) => (
        <Route key={i} {...route} />
      ))}
    </Routes>
  );
};
