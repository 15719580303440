import { FC } from "react";

interface SubtitleProps {
  content: string;
}

export const Subtitle: FC<SubtitleProps> = ({ content }) => {
  return (
    <h1 className="font-medium text-20 text-grey-500 uppercase">{content}</h1>
  );
};
