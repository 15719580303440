export const Cyclist = () => {
  return (
    <svg width="28" height="28" fill="none" viewBox="0 0 28 28">
      <path
        stroke="#1E1863"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.029"
        d="M13.324 22.118l2.03-5.412L8.747 14l4.059-4.735 4.059 3.382H21.6m-4.895-5.412a2.706 2.706 0 100-5.411 2.706 2.706 0 000 5.411zm5.412 18.942a4.06 4.06 0 100-8.12 4.06 4.06 0 000 8.12zm-16.235 0a4.06 4.06 0 100-8.119 4.06 4.06 0 000 8.119z"
      ></path>
    </svg>
  );
};

export const Dart = () => {
  return (
    <svg width="32" height="32" fill="none" viewBox="0 0 32 32">
      <path
        fill="#1E1863"
        d="M26.275 9.399h-2.34l-5.741 5.738a2.357 2.357 0 11-1.333-1.332l5.74-5.74v-2.34c0-.313.124-.613.346-.835l2.175-2.175a.313.313 0 01.314-.08.312.312 0 01.218.24l.578 2.894 2.892.578a.312.312 0 01.243.385.314.314 0 01-.082.146l-2.177 2.175a1.178 1.178 0 01-.833.346z"
      ></path>
      <path
        fill="#1E1863"
        d="M4.212 16a11.788 11.788 0 1023.576 0c0-1.13-.159-2.22-.454-3.253a.943.943 0 011.811-.52c.345 1.2.529 2.465.529 3.773 0 7.552-6.122 13.674-13.674 13.674S2.326 23.552 2.326 16 8.448 2.326 16 2.326a13.669 13.669 0 013.772.527.943.943 0 11-.52 1.813A11.807 11.807 0 0016 4.212 11.788 11.788 0 004.212 16z"
      ></path>
      <path
        fill="#1E1863"
        d="M9.87 16a6.127 6.127 0 0010.817 3.921 6.129 6.129 0 001.401-4.636.94.94 0 011.316-1.004c.316.141.529.441.556.786A8.013 8.013 0 018.1 17.341a8.017 8.017 0 018.82-9.304.943.943 0 11-.214 1.874A6.13 6.13 0 009.87 16z"
      ></path>
    </svg>
  );
};

export const Profile = () => {
  return (
    <svg width="28" height="28" fill="none" viewBox="0 0 28 28">
      <path
        stroke="#1E1863"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.011"
        d="M7.296 1.934H4.615a2.681 2.681 0 00-2.681 2.681v2.682m0 13.406v2.682a2.681 2.681 0 002.681 2.681h2.681m13.407 0h2.682a2.681 2.681 0 002.68-2.681v-2.682m0-13.406V4.615a2.682 2.682 0 00-2.68-2.681h-2.682M14 14a4.022 4.022 0 100-8.044A4.022 4.022 0 0014 14zM20.703 19.363C20.703 16.4 17.701 14 14 14c-3.702 0-6.704 2.4-6.704 5.363"
      ></path>
    </svg>
  );
};
