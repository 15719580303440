import { Envelope } from "../icons/Envelope";
import { Facebook } from "../icons/Facebook";
import { Instagram } from "../icons/Instagram";
import { Strava } from "../icons/Strava";
import { Youtube } from "../icons/Youtube";

export const Footer = () => {
  return (
    <div className="shrink-0 w-full h-[120px] lg:h-[200px] bg-black flex flex-col items-center justify-center text-grey-300">
      <div className="flex gap-[16px] mb-[16px] lg:mb-[24px]">
        <a href="https://www.facebook.com/sbu2017">
          <Facebook />
        </a>
        <a href="https://www.instagram.com/slavonskabbliga/">
          <Instagram />
        </a>
        <a href="https://www.youtube.com/@slavonskabiciklstickaudruga">
          <Youtube />
        </a>
        <a href="https://www.strava.com/clubs/sbbl">
          <Strava />
        </a>
      </div>
      <div className="flex gap-[4px] mb-[4px] lg:mb-[12px] items-center">
        <Envelope />{" "}
        <a href="mailto: slavonskabbliga@gmail.com">
          slavonskabbliga@gmail.com
        </a>
      </div>
      <p>
        <small>&copy; Copyright {new Date().getFullYear()}, SBU</small>
      </p>
    </div>
  );
};
