import { useMemo, useRef } from "react";
import { trailList } from "../teams/teamData";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../RoutePath";
import Map, { MapRef, Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import bbox from "@turf/bbox";
import { lineString } from "@turf/helpers";
import { useWindowResize } from "../../hooks/useWindowResize";
import mapboxgl from "mapbox-gl";
import { Info } from "../../components/icons/Info";
import { Title } from "../../components/typography/Title";

// @ts-ignore
// prettier-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export const Trails = () => {
  const { isMobile } = useWindowResize();
  const navigate = useNavigate();
  const center = useMemo(
    () => ({
      lat: 45.452792,
      lng: 17.933277,
    }),
    [],
  );

  const mapRef = useRef<MapRef>(null);

  return (
    <div className="max-w-[1440px] my-0 mx-auto px-[16px] pt-[80px] pb-[40px] lg:pb-[80px] lg:pt-[120px]">
      <div className="space-y-[16px]">
        <Title content="Staze" bolded={true} />
        <p className="text-16">
          Diljem Slavonije osvanulo je osam sjajnih XCO/XCM staza koje
          predstavljaju pravu poslasticu za biciklističke entuzijaste. Staze su
          pažljivo planirane kako bi pružile raznovrsno iskustvo, za početnike a
          i one iskusne. Sudjelujući u Slavonsko brdsko biciklističkoj ligi imat
          ćete priliku vidjeti i isprobati svaku od njih!
        </p>
        <div className="flex gap-[8px] items-center">
          <Info className="text-alert-100" />{" "}
          <p className="text-18">
            Na mapi su označene staze. Klikom na neku, saznat ćeš više o njoj!
          </p>
        </div>
        <div className="w-full">
          <Map
            ref={mapRef}
            onLoad={() => {
              var line = lineString([
                ...trailList.map((x) => {
                  return [x.y, x.x];
                }),
              ]);
              var [x1, y1, x2, y2] = bbox(line);
              mapRef.current!.fitBounds([x1, y1, x2, y2], { padding: 38 });
            }}
            style={{
              width: "100%",
              height: isMobile ? "300px" : "500px",
            }}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={{
              longitude: center.lng,
              latitude: center.lat,
              zoom: 8,
            }}
            mapStyle="mapbox://styles/mapbox/streets-v12"
          >
            {trailList.map((x, i) => (
              <Marker latitude={x.x} longitude={x.y} key={i}>
                <div
                  className="relative bg-primary-500 text-14 text-white text-center p-[8px] w-[160px] rounded border border-grey-100"
                  onClick={() => navigate(`${RoutePath.TRAILS}/${x.id}`)}
                >
                  {x.type} {x.name}
                  <div className="absolute w-[10px] h-[10px] bg-primary-500 -bottom-[6px] left-[80px] rotate-45 border-b border-r border-grey-100"></div>
                </div>
              </Marker>
            ))}
          </Map>
        </div>
      </div>
    </div>
  );
};
