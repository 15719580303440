import { FC, PropsWithChildren } from "react";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";

interface PageProps {
  title?: string;
}

export const Page: FC<PropsWithChildren<PageProps>> = ({ title, children }) => {
  return (
    <div className="h-screen flex flex-col">
      <Navbar />
      <div className="flex-1">
        <div>{children}</div>
      </div>
      <Footer />
    </div>
  );
};
