export enum RoutePath {
  HOME = "/",
  ABOUT = "/o-nama",
  TEAMS = "/klubovi",
  TEAM_DETAILS = "/klubovi/:id",
  TRAILS = "/staze",
  TRAIL_DETAILS = "/staze/:id",
  CALENDAR = "/kalendar",
  NOT_FOUND = "*",
}
