import { FC } from "react";

export interface SponsorCardProps {
  imgUrl: string;
  website: string;
  variant?: "small" | "large";
}

export const SponsorCard: FC<SponsorCardProps> = ({
  imgUrl,
  website,
  variant = "large",
}) => {
  const sizeClass =
    variant === "large"
      ? "lg:w-[200px] lg:h-[200px]"
      : "lg:w-[140px] lg:h-[140px]";
  return (
    <div className={`flex items-center ${sizeClass}`}>
      <a href={website} target="_blank" rel="noreferrer">
        <img src={imgUrl} alt="" className={`w-full `} />
      </a>
    </div>
  );
};
