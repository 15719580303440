import { FC, HtmlHTMLAttributes, PropsWithChildren } from "react";

const circleSizes = {
  40: "w-[40px] h-[40px]",
  63: "w-[63px] h-[63px]",
};

const cicleVariants = {
  dark: "bg-secondary-100",
  light: "bg-secondary-10",
};

interface CircleProps extends HtmlHTMLAttributes<HTMLElement> {
  size?: 40 | 63;
  variant?: "dark" | "light";
}

export const Circle: FC<PropsWithChildren<CircleProps>> = ({
  children,
  size = 63,
  variant = "dark",
  ...props
}) => {
  return (
    <div
      className={`rounded-full flex justify-center items-center ${cicleVariants[variant]} ${circleSizes[size]} ${props.className}`}
    >
      {children}
    </div>
  );
};
