import { useParams } from "react-router-dom";
import { Title } from "../../components/typography/Title";
import { Subtitle } from "../../components/typography/Subtitle";
import { Circle } from "../../components/elements/Circle";
import { Facebook } from "../../components/icons/Facebook";
import { Instagram } from "../../components/icons/Instagram";
import { Web } from "../../components/icons/Web";
import { teamList, trailList } from "./teamData";
import { Directions } from "../../components/icons/Directions";
import { RoutePath } from "../../RoutePath";
import { NotFound } from "../NotFound";

const LabelDesc = (props: { label: string; desc: string }) => {
  const { label, desc } = props;
  return (
    <p className="text-16">
      {label}
      <span className="text-grey-500"> {desc}</span>
    </p>
  );
};

export const TeamDetails = () => {
  const { id } = useParams<{ id: string }>();
  const team = teamList.find((x) => x.id === Number(id));

  if (!team) {
    return <NotFound />;
  }

  const trails = trailList.filter((x) => team.traildId?.indexOf(x.id) >= 0);
  return (
    <div className="max-w-[1440px] my-0 mx-auto">
      <div className="px-[16px] pt-[80px] pb-[40px] lg:pb-[80px] lg:pt-[120px]">
        <div className="flex flex-col gap-[16px] lg:flex-row lg:items-end justify-between">
          <Title content={team?.name} className="order-2" />
          <div className="flex gap-[12px] self-center order-1 lg:order-2">
            {team.facebook && (
              <a href={team.facebook} target="_blank" rel="noreferrer">
                <Circle className="bg-primary-100">
                  <Facebook />
                </Circle>
              </a>
            )}
            {team.instagram && (
              <a href={team.instagram} target="_blank" rel="noreferrer">
                <Circle className="bg-primary-100">
                  <Instagram />
                </Circle>
              </a>
            )}
            {team.web && (
              <a href={team.web} target="_blank" rel="noreferrer">
                <Circle className="bg-primary-100">
                  <Web />
                </Circle>
              </a>
            )}
          </div>
        </div>
        <div className="space-y-[24px] mt-[24px]">
          <p
            className="text-16 text-justify"
            dangerouslySetInnerHTML={{ __html: team.description }}
          ></p>
          <div className="grid lg:grid-cols-2 gap-[16px]">
            <div className="grid lg:grid-cols-1 gap-[12px] lg:justify-center justify-start order-2 lg:order-1">
              <div className="grid grid-cols-1 lg:grid-cols-2 h-fit gap-[26px]">
                <div>
                  <Subtitle content="Broj čanova" />
                  <p className="text-32 font-bold">{team.numberOfMembers}</p>
                </div>
                {trails && trails.length > 0 && (
                  <div>
                    <Subtitle content="Staze" />
                    {trails.map((x, i) => (
                      <a
                        href={`${RoutePath.TRAILS}/${x?.id}`}
                        className="flex gap-[8px] items-center"
                      >
                        <Directions />
                        <p className="text-20">
                          {x?.type} {x?.name}
                        </p>
                      </a>
                    ))}
                  </div>
                )}
                <div>
                  <Subtitle content="Vodstvo" />
                  <div>
                    {team.president && (
                      <LabelDesc label={team.president} desc="predsjednik" />
                    )}
                    {team.vicePrecident && (
                      <LabelDesc
                        label={team.vicePrecident}
                        desc="dopredsjednik"
                      />
                    )}
                    {team.secretary && (
                      <LabelDesc label={team.secretary} desc="tajnik" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <img
              className="w-full lg:w-[800px] order-1"
              src={team.teamImg}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
