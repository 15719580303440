import { Navigate, RouteProps } from "react-router-dom";
import { RoutePath } from "./RoutePath";
import { NotFound } from "./pages/NotFound";
import { Page } from "./components/layout/Page";
import { About } from "./pages/about/About";
import { Calendar } from "./pages/calendar/Calendar";
import { TeamDetails } from "./pages/teams/TeamDetails";
import { Teams } from "./pages/teams/Teams";
import { Trails } from "./pages/trails/Trails";
import { TrailDetails } from "./pages/trails/TrailDetails";

export const AppRoutes: RouteProps[] = [
  {
    path: RoutePath.HOME,
    element: <Navigate to={RoutePath.ABOUT} />,
  },
  {
    path: RoutePath.ABOUT,
    element: (
      <Page title="O nama">
        <About />
      </Page>
    ),
  },
  {
    path: RoutePath.TEAMS,
    element: (
      <Page title="Klubovi">
        <Teams />
      </Page>
    ),
  },
  {
    path: RoutePath.TEAM_DETAILS,
    element: (
      <Page>
        <TeamDetails />
      </Page>
    ),
  },
  {
    path: RoutePath.TRAILS,
    element: (
      <Page title="Staze">
        <Trails />
      </Page>
    ),
  },
  {
    path: RoutePath.TRAIL_DETAILS,
    element: (
      <Page title="Staze">
        <TrailDetails />
      </Page>
    ),
  },
  {
    path: RoutePath.CALENDAR,
    element: (
      <Page title="Kalendar">
        <Calendar />
      </Page>
    ),
  },
  {
    path: RoutePath.NOT_FOUND,
    element: (
      <Page>
        <NotFound />
      </Page>
    ),
  },
];
