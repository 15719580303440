import { FC } from "react";

export interface ProfileCardProps {
  imgUrl: string;
  firstName: string;
  lastName: string;
  role: string;
  onClick?: () => void;
  size?: "small" | "large";
}

export const ProfileCard: FC<ProfileCardProps> = ({
  imgUrl,
  firstName,
  lastName,
  role,
  onClick,
  size = "large",
}) => {
  const sizeClass =
    size === "large" ? "h-[326px] w-[326px]" : "h-[240px] w-[240px]";
  return (
    <div
      className={`shadow-md w-fit ${onClick ? "cursor-pointer" : ""}`}
      onClick={onClick}
    >
      <div className={`${sizeClass} flex`}>
        <img src={imgUrl} alt="" className="w-full h-auto object-contain" />
      </div>
      <div className="px-[16px] py-[8px]">
        <p className="text-20">
          {firstName} {lastName}
        </p>
        <p className="text-grey-500">{role}</p>
      </div>
    </div>
  );
};
