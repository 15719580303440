import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MenuItem, menuItems } from "./menu-items";
import { useWindowResize } from "../../../hooks/useWindowResize";

interface MenuProps {
  onMenuItemClick?: () => void;
}

export const Menu: FC<MenuProps> = ({ onMenuItemClick }) => {
  return (
    <nav className="lg:mt-[0px] mt-[12px]">
      <ul className="lg:flex lg:gap-[40px] text-blue">
        {menuItems.map((x, i) => (
          <MenuLink
            key={i}
            menuItem={x}
            onClick={onMenuItemClick}
            Icon={<x.Icon />}
          />
        ))}
      </ul>
    </nav>
  );
};

interface MenuLinkProps {
  menuItem: MenuItem;
  onClick?: () => void;
  Icon: JSX.Element;
}

const MenuLink: FC<MenuLinkProps> = ({ menuItem, onClick, Icon }) => {
  const location = useLocation();
  const { isMobile } = useWindowResize();
  const itemClass = "gap-[12px] lg:gap-[40px] py-[10px]";
  const isActive = location.pathname.indexOf(menuItem.path) >= 0;
  const activeClass = isActive
    ? "lg:border-b-[2px] lg:border-white"
    : "opacity-70";
  return (
    <NavLink to={menuItem.path}>
      <li
        onClick={onClick}
        className={`text-white text-18 whitespace-nowrap font-semibold flex items-center px-[32px] ${itemClass} ${activeClass}`}
      >
        {isMobile && Icon}
        {menuItem.title}
      </li>
    </NavLink>
  );
};
