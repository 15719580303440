import { SponsorCardProps } from "../../components/elements/SponsorCard";

export const sponsors: SponsorCardProps[] = [
  {
    imgUrl: "/images/sponsors/simpil.png",
    website: "https://www.simpil-bikes.hr/",
  },
  {
    imgUrl: "/images/sponsors/farmakol.png",
    website: "https://www.farmakol.hr/",
  },
  {
    imgUrl: "/images/sponsors/pip.png",
    website: "https://www.pip.hr/",
  },
  {
    imgUrl: "/images/sponsors/mtka.png",
    website: "https://os.mtka.hr/",
  },
  {
    imgUrl: "/images/sponsors/segin.png",
    website: "https://www.facebook.com/bikefittinggema/",
  },
];
