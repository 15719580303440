import { SVGProps } from "react";

export const Envelope = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1_17070)"
      >
        <path d="M19 5H5a2 2 0 00-2 2v10a2 2 0 002 2h14a2 2 0 002-2V7a2 2 0 00-2-2z"></path>
        <path d="M3 7l9 6 9-6"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_17070">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
