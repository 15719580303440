import { Cyclist, Dart, Profile } from "../../icons";
import { InfoCard, InfoCardProps } from "../../components/elements/InfoCard";
import {
  ProfileCard,
  ProfileCardProps,
} from "../../components/elements/ProfileCard";
import { SponsorCard } from "../../components/elements/SponsorCard";
import { sponsors } from "./sponsors";
import { ArrowRight } from "../../components/icons/ArrowRight";
import { Envelope } from "../../components/icons/Envelope";
import { Button } from "../../components/button/Button";
import { Title } from "../../components/typography/Title";
import { Download } from "../../components/icons/Download";

const aboutData: InfoCardProps[] = [
  {
    title: "Tko smo i što radimo?",
    description:
      "Slavonska biciklistička udruga okuplja osam biciklističkih klubova u Slavoniji i Baranji. <br/> Jedna od glavnih aktivnosti udruge je organizacija Slavonske Brdsko Biciklističke Lige (SBBL).",
    Icon: <Cyclist />,
  },
  {
    title: "Koji su naši ciljevi?",
    description:
      "Glavni ciljevi su nam promocija biciklističkog sporta, organiziranje raznih natjecateljskih i rekreativnih MTB utrka.",
    Icon: <Dart />,
  },
  {
    title: "Tko može postati članom?",
    description:
      "Svi pojedinci i biciklistički klubovi na području Slavonije i Baranje.",
    Icon: <Profile />,
  },
];

const team: ProfileCardProps[] = [
  {
    imgUrl: "/images/predsjednik.png",
    firstName: "Ivica",
    lastName: "Kerep",
    role: "Predsjednik",
  },
  {
    imgUrl: "/images/tajnik.png",
    firstName: "Lorena",
    lastName: "Škalac",
    role: "Tajnik",
  },
];

const dueDatePredprijave = new Date(2024, 2, 15, 23, 59, 59);

export const About = () => {
  return (
    <>
      <div>
        <div className="hero h-[347px] lg:h-[647px]">
          <div className="text-white max-w-[1440px] my-0 mx-auto relative px-[16px]">
            <div className="absolute translate-y-2/4 top-[50%]">
              <h1 className="font-bold text-32 lg:text-56">
                Slavonska biciklistička <br /> udruga (SBU)
              </h1>
              <p className="text-18 lg:text-26">
                Organizator Slavonske brdsko biciklističke lige (SBBL)
              </p>
            </div>
            {new Date() < dueDatePredprijave && (
              <a
                className="absolute text-white right-[16px] top-[220px] lg:top-[490px] flex gap-[4px] border border-white rounded px-[8px] cursor-pointer"
                href="https://docs.google.com/forms/d/e/1FAIpQLScjJQKt8PtCCmxZ-K7thND_VHkmRpVnzHO6gz6hPLbvNn5sZg/viewform"
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-26">S/2024 pretprijave </p>
                <ArrowRight height={40} width={40} />
              </a>
            )}
            <a
              href="/files/sbbl_pravilnik_2024.pdf"
              download
              className="absolute items-center text-26 text-white right-[16px] top-[280px] lg:top-[560px] flex gap-[4px] border border-white rounded px-[8px] py-[8px] cursor-pointer"
            >
              <Download /> SBBL PRAVILNIK
            </a>
          </div>
        </div>
        <div className="max-w-[1440px] my-0 mx-auto py-[50px] lg:py-[80px]">
          <div className="space-y-[40px] lg:space-y-[80px]">
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-[20px] px-[12px]">
              {aboutData.map((x, i) => (
                <InfoCard key={i} {...x} />
              ))}
            </div>
            <div>
              <Title
                content="Tko stoji iza svega?"
                className="mb-[28px] text-center"
                as="h2"
              />
              <div className="flex flex-col lg:flex-row gap-[20px] justify-center items-center">
                {team.map((x, i) => (
                  <ProfileCard key={i} {...x} />
                ))}
              </div>
            </div>
            <div className="px-[12px] space-y-[28px]">
              <Title
                content="Koji su naši partneri?"
                className="mb-[28px] text-center"
                as="h2"
              />
              <div className="flex flex-col items-center gap-[24px]">
                <div className="w-full grid grid-cols-1 lg:grid-cols-5 px-[12px] gap-[20px]">
                  {sponsors.map((x, i) => (
                    <SponsorCard key={i} {...x} />
                  ))}
                </div>
                <div className="flex flex-col gap-[12px] items-center justify-center border border-grey-200 w-full h-[200px] lg:w-[240px]">
                  <p className="text-20 text-center">
                    Postani naš partner <br /> i osiguraj si ovo mjesto
                  </p>
                  <a href="mailto: slavonskabbliga@gmail.com">
                    <Button
                      Icon={Envelope}
                      title="Javi se"
                      variant="outlined"
                      className="!w-fit"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
