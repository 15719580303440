import { SVGProps } from "react";
import { RoutePath } from "../../../RoutePath";
import { Home } from "../../icons/Home";
import { Teams } from "../../icons/Teams";
import { Calendar } from "../../icons/Calendar";
import { Directions } from "../../icons/Directions";

export interface MenuItem {
  title: string;
  path: RoutePath;
  Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

export const menuItems: MenuItem[] = [
  {
    title: "O nama",
    path: RoutePath.ABOUT,
    Icon: Home,
  },
  {
    title: "Članovi",
    path: RoutePath.TEAMS,
    Icon: Teams,
  },
  {
    title: "Staze",
    path: RoutePath.TRAILS,
    Icon: Directions,
  },
  {
    title: "Kalendar",
    path: RoutePath.CALENDAR,
    Icon: Calendar,
  },
];
