import { useEffect, useMemo, useState } from "react";

export const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const isMobile = useMemo(() => {
    return windowSize < 1024;
  }, [windowSize]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return {
    windowSize,
    isMobile,
  };
};
