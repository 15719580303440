import { SVGProps } from "react";

export const Youtube = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1_11849)"
      >
        <path d="M17 5H7a4 4 0 00-4 4v6a4 4 0 004 4h10a4 4 0 004-4V9a4 4 0 00-4-4z"></path>
        <path d="M10 9l5 3-5 3V9z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_11849">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
