import { useNavigate } from "react-router-dom";
import { Button } from "../components/button/Button";
import { Title } from "../components/typography/Title";
import { RoutePath } from "../RoutePath";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="max-w-[1440px] my-0 mx-auto px-[16px] pt-[80px] pb-[40px] lg:pb-[80px] lg:pt-[120px]">
      <div className="space-y-[16px] flex flex-col items-center">
        <Title content="Oooops! 404" />
        <div className="text-center">
          <p className="text-20"> Stranica koju tražiš nije pronađena</p>
          Provjeri je li URL dobro upisan.
          <br /> Uvijek se možeš vratiti na naslovnu ili odabrati drugu iz
          navigacije!
        </div>
        <Button
          title="Idi na naslovnu"
          className="!w-[200px]"
          onClick={() => navigate(RoutePath.HOME)}
        />
      </div>
    </div>
  );
};
