export const Gallery = () => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1_18108)"
      >
        <path d="M15 8h.01M17 4H7a3 3 0 00-3 3v10a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3z"></path>
        <path d="M4 15l4-4c.456-.439.973-.67 1.5-.67s1.044.231 1.5.67l5 5"></path>
        <path d="M14 14l1-1c.456-.439.973-.67 1.5-.67s1.044.231 1.5.67l2 2"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_18108">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
