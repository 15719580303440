import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./styles/index.scss";
import { BrowserRouter } from "react-router-dom";
import { ScrollToTop } from "./hooks/useScrollToTop";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>,
);
